<template>

  <v-app id="inspire">
      <Header :drawer="$store.state.drawer"></Header>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    <v-navigation-drawer v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-if="first_login_flag !='1'">
          <router-link v-if='this.user.uid=="superadmin@easypariksha.com"' to="/eps-admin/home" class="sidebar-link">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link v-else to="/home" class="sidebar-link">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <v-divider></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- <eps-header :drawer="$store.state.drawer"></eps-header>s -->
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5" lg="7">
            <v-card class="elevation-12">
              <v-toolbar dark flat>
                <v-spacer />
                <v-toolbar-title>Change Password</v-toolbar-title>
                <v-spacer />
                <router-link to="/home" style="text-decoration: none">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" class="mx-2 button-cancel" fab small ><v-icon dark>mdi-close</v-icon></v-btn>
                    </template>
                    <span>Close</span>
                  </v-tooltip>
                </router-link>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user.oldpassword"
                    :rules="[v => !!v || 'Old Password is required']"
                    prepend-icon=" mdi-account-key"
                    placeholder="Current Password"
                    :disabled="disabled == 1 ? true : false"
                    clearable
                    :append-icon="value2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value2 = !value2)"
                    :type="value2?'password':'text'"
                    required
                  ></v-text-field>
                  <div class="error-message" v-if="error">{{error_message}}</div>
                  <v-layout align-center justify-center v-if="hideen">
                    <v-btn class="mr-4" color="primary" @click="validateoldpassword">Check Password</v-btn>
                  </v-layout>
                  <v-text-field
                    v-if="!hideen"
                    v-model="user.password"
                    :rules="[v => !!v || 'Password is required']"
                    prepend-icon=" mdi-account-key"
                    placeholder="New Password"
                    clearable
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    hint="*Password must be at least 4 characters!"
                    required
                  ></v-text-field>
                  <!-- In 8 digit password, Atleast 1 number, special characters and small / capital letters required. -->
                  <v-text-field
                    v-if="!hideen"
                    v-model="user.confirmpassword"
                    :rules="[v => !!v || 'Confirm Password is required']"
                    prepend-icon=" mdi-account-key"
                    placeholder="Confirm Password"
                    clearable
                    :append-icon="value1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value1 = !value1)"
                    :type="value1 ? 'password' : 'text'"
                    required
                  ></v-text-field>
                  <div class="error-message" v-if="error1">{{error_message1}}</div>
                  <v-layout align-center justify-center v-if="!hideen">
                    <v-btn class="mr-4" color="primary" @click="setpassword">Change Password</v-btn>
                  </v-layout>
                </v-form>
              </v-card-text>
              <div v-if="first_login_flag !='1'">
              <router-link v-if='this.user.uid=="superadmin@easypariksha.com"' to="eps-admin/home" style="text-decoration: none" title="Back">
                <v-icon large color="grey darken">mdi-chevron-left-circle</v-icon>
              </router-link>
              <!-- <router-link v-else to="/home" style="text-decoration: none" title="Back">
                <v-icon large color="grey darken">mdi-chevron-left-circle</v-icon>
              </router-link>  -->
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import axios from "axios";
import Header from "../components/shared/Header";
export default {
  components: {
    Header: Header
  },
  data() {
    return {
      user: {
        uid: "",
        oldpassword: "",
        password: "",
        confirmpassword: ""
      },
      value: String,
      value1: String,
      value2: String,
      hideen: true,
      disabled: 0,
      message: "",
      error: false,
      error_message: "",
      error1: false,
      error_message1: "",
      first_login_flag:null,
      snackbar_msg: "",
      color: "",
      snackbar: false,
      // usertype:''
    };
  },
  methods: {
    validateoldpassword() {
      if (this.perform_old_password_form_validation()) {
        //console.log("I am in If block :: " + this.user.oldpassword);
        this.user.dns = window.location.hostname;
        this.user.uid = localStorage.getItem("EPS-uid");
        //console.log("this.user.uid :: " + this.user.uid);
        axios
          .post("/login/checkpassword", this.user)
          .then(res => {
            //window.console.log("res" + res.data.msg);
            if (res.data.msg == "200") {
              this.hideen = false;
              this.disabled = (this.disabled + 1) % 2;
              this.error_message="";
            } else {
              //console.log("error :: ");
              this.message = "Password not Match!!!";
              this.error = true;
              this.error_message = "Old Password does not match!";
            }
          })
          .catch(error => {
            window.console.log(error);
            this.message = "Something went wrong, Please try again later!";
          });
      } else {
        //console.log("I am in else block :: ");
        this.error = true;
        this.error_message = "Please enter current Password!";
      }
    },
    setpassword() {
      if (
        this.user.password == this.user.confirmpassword &&
        this.user.password.length > 0 &&
        this.user.confirmpassword.length > 0
      ) {
        if(this.user.password.length < 4)
          {
          //console.log("I am in else block :: " + this.user.password);
          this.error1 = true;
          this.error_message1 = "The password must be at least 4 characters!";
        }
        else if(this.user.password.length > 12)
          {
          //console.log("I am in else block :: " + this.user.password);
          this.error1 = true;
          this.error_message1 = "Too long! the password must not exceed 12 characters!";
        } 
        else{
          if (this.perform_Change_Password_Form_Validation()) {
        //   console.log(
        //     "I am in If block :: " +
        //       this.user.password +
        //       " :: " +
        //       this.user.confirmpassword
        //   );
          this.user.dns = window.location.hostname;
          this.user.uid = localStorage.getItem("EPS-uid");
          //console.log("this.user.uid :: " + this.user.uid);
          axios
            .post("/login/changepassword", this.user)
            .then(res => {
              //window.console.log("res" + res.data.msg);
              if (res.data.msg == "200") {
                localStorage.setItem("first-login", "0");
                window.alert("Password changed successfully...")
                // console.log("res.data.usertype: "+res.data.usertype)
                if(res.data.uid=="superadmin@easypariksha.com"){
                  this.$router.push("/eps-admin/home");
                } else {
                  this.$router.push("home");
                }
                //this.$router.push("/home");
                //this.$router.push("/");
              }else if(res.data.msg == "samepassword") {
                // this.message = "New Password is same as Old Password!";
                this.error1 = true;
                this.error_message1 = "New Password is same as old password, Please try something else!";
              } 
              else {
                this.message = "Password not Match!!!";
                this.error1 = true;
                this.error_message1 = "Password Not Match..!";
              }
            })
            .catch(error => {
              window.console.log(error);
              this.message = "Something went wrong";
            });
          } else {
            //console.log("I am in else block :: " + this.user.password);
            this.error1 = true;
            this.error_message1 = "Password does not match!";
          }
        }
      } 
      else {
        //console.log("I am in else block :: " + this.user.password);
        this.error1 = true;
        this.error_message1 = "Password does not match!";
      }
    },
    perform_Change_Password_Form_Validation() {
      if(this.user.password=="" && this.user.confirmpassword=="")
      {
        return false;
      }
      else{
       return true;
      }
    },
    perform_old_password_form_validation() {
      if(this.user.oldpassword=="")
      {
        return false;
      }
      else{
       return true;
      }
    },
    showPassword() {
        if(this.type === 'password') {
            this.type = 'text';
            this.btnText = 'Hide Password';
        } else {
            this.type = 'password';
            this.btnText = 'Show Password';
        }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  mounted() {
    this.message=localStorage.getItem('login-page-msg')
    this.first_login_flag=localStorage.getItem('first-login')
    this.user.uid = localStorage.getItem("EPS-uid");
    if(this.first_login_flag == "1"){
      this.showSnackbar("#b71c1c", "You are logging into the system for the first time, please change your password!"); // show snackbar
    }
  }
};
</script>
<style scoped>
  .sidebar-link {
    text-decoration: none;
  }
  .sidebar-link-item:hover {
    background-color: rgb(181, 186, 190) !important;
  }
  .pull-right{
      position: relative;
      text-align: center;
  }
  .error-message {
    color : red;
    font-size : 14px;
    font-weight : 900;
    padding-left : 7%;
  }
</style>